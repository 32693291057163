import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
import Widgets from 'src/pages/Widgets.vue';
// custom
import QuickServe from '../pages/custom/QuickServe'
import StatPage from '../pages/custom/StatPage'
import StatBranchPage from '../pages/custom/StatBranchPage'
import StatQueuePage from '../pages/custom/StatQueuePage'
import EnhancedStatQueuePage from '../pages/custom/EnhancedStatQueuePage'
import ApplicationAdmin from '../pages/custom/ApplicationAdmin'
import BranchAdmin from '../pages/custom/BranchAdmin'
import QueueAdmin from '../pages/custom/QueueAdmin'
import ResourceAdmin from '../pages/custom/ResourceAdmin'
import UserAdmin from '../pages/custom/UserAdmin'
import HomePage from '../pages/custom/HomePage'
import ReceptionPage from '../pages/custom/ReceptionPage'
import WorkstationPage from '../pages/custom/WorkstationPage'
import NexmoLookupPage from '../pages/custom/NexmoLookupPage'
import ServicePointAdmin from '../pages/custom/ServicePointAdmin'
import CreateStationAdmin from '../pages/custom/CreateStationAdmin'
import TicketLookup from '../pages/custom/TicketLookup'

// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue');
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Buttons.vue');
const GridSystem = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/GridSystem.vue');
const Panels = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Panels.vue');
const SweetAlert = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/SweetAlert.vue');
const Notifications = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Notifications.vue');
const Icons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Icons.vue');
const Typography = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Typography.vue');

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');

// Forms pages
const RegularForms = () => import('src/pages/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Forms/Wizard.vue');

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/TimeLinePage.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const RTL = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/RegularTables.vue');
const ExtendedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/ExtendedTables.vue');
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/PaginatedTables.vue');

const AUTH_TYPE_ROLE_USER = 'ROLE_USER';
const AUTH_TYPE_ROLE_ADMIN = 'ROLE_ADMIN';
const AUTH_TYPE_ROLE_ANON = 'anon';


let authPages = {
  path: '/login',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta:{authType: AUTH_TYPE_ROLE_ANON,}
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock,
      meta:{authType: AUTH_TYPE_ROLE_ANON,}
    }
  ]
};

let adminPages = {
  path: '/',
  component: DashboardLayout,
  name: 'Admin',
  children: [
    {
      path: '/applicationAdmin',
      name: 'Application Admin',
      component: ApplicationAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/branchAdmin',
      name: 'Branch Admin',
      component: BranchAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/servicePointAdmin',
      name: 'Service Point Admin',
      component: ServicePointAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/createStationAdmin',
      name: 'Create Station Admin',
      component: CreateStationAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/queueAdmin',
      name: 'Queue Admin',
      component: QueueAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/resourceAdmin',
      name: 'resource Admin',
      component: ResourceAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/userAdmin',
      name: 'User Admin',
      component: UserAdmin,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    },
    {
      path: '/smsLookup',
      name: 'SMS lookup',
      component: NexmoLookupPage,
      meta:{authType: AUTH_TYPE_ROLE_ADMIN,}
    }
  ]
};


const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Login'
  },
  authPages,
  adminPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'quickServe',
        name: 'Quick Serve',
        components: {default: QuickServe},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'homePage',
        name: 'Home',
        components: {default: HomePage},
        authType: AUTH_TYPE_ROLE_USER,
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'statPage',
        name: 'Stats',
        components: {default: StatPage},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'statBranchPage',
        name: 'Branch Stats',
        components: {default: StatBranchPage},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'statQueuePage',
        name: 'Queue Stats',
        components: {default: StatQueuePage},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'enhancedStatQueuePage',
        name: 'Enhanced Queue Stats',
        components: {default: EnhancedStatQueuePage},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'ticketLookup',
        name: 'Ticket Lookup',
        components: {default: TicketLookup},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'workstationPage',
        name: 'Workstation',
        components: {default: WorkstationPage},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'receptionPage',
        name: 'Reception',
        components: {default: ReceptionPage},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: {default: Calendar},
        meta:{authType: AUTH_TYPE_ROLE_USER,}
      },

    ]
  },
  {path: '*', component: NotFound}
];

export default routes;
