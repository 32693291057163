<template>
  <div>
    <card type='card-danger' class="text-center" style="">
      <h1 class="card-title">User Admin</h1>
      <h4 class="card-text">Use this page to globally manage Users and Roles</h4>
    </card>
    <div class="row">
      <div class="col-md-12">
        <form>
          <card title="">
            <div class="row card-body">
              <div class="col-md-6">
                <card>
                  <div class="card-body">
                    <base-input label="Username"
                                type="text"
                                placeholder="username" v-model="model.txtUsername">
                    </base-input>
                    <base-input
                      type="email"
                      required
                      name="email"
                      label="Email address"
                      v-validate="modelValidations.email"
                      :error="getError('email')"
                      v-model="model.txtEmail"
                    >
                    </base-input>

                    <base-input
                      label="Password"
                      required
                      type="password"
                      name="password"
                      v-validate="modelValidations.password"
                      :error="getError('password')"
                      v-model="model.txtPassword"
                    >
                    </base-input>

                    <base-input
                      label="Confirm Password"
                      required
                      type="password"
                      name="confirm"
                      v-validate="modelValidations.confirmPassword"
                      :error="getError('confirm')"
                      v-model="model.txtConfirmPassword"
                    ></base-input>

                  </div>
                </card>
              </div>
              <div class="col-md-3">
                <card title="Roles">
                  <div class="card-body">
                    <div v-for="role in roles">
                      <base-checkbox class="mb-3" v-model="role.selected" v-if="role.role!=='SUPERADMIN'">
                        {{role.role}}
                      </base-checkbox>
                    </div>
                  </div>
                </card>
              </div>
              <div class="col-md-3">
                <card disabled title="Branches">
                  <div class="card-body">
                    <div v-for="branch in branches.tableData">
                      <base-checkbox class="mb-3" v-model="branch.selected">
                        {{branch.branchName}}
                      </base-checkbox>
                    </div>
                  </div>

                  <div class="col-md-5">Default branch
                    <BranchSelect
                      :propergateGlobally="propergateGlobally">
                    </BranchSelect>
                  </div>
                </card>
              </div>


            </div>
            <base-button type="primary" class="animation-on-hover" block @click.prevent="validate">Add User
            </base-button>
          </card>


        </form>

      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card title="All Users">
          <div class="card-body">
            <el-table :data="users.tableData">
              <el-table-column min-width="50" type="index"></el-table-column>
              <el-table-column min-width="125" prop="userName" label="User Name"></el-table-column>
              <el-table-column min-width="170" prop="email" label="Email"></el-table-column>
              <el-table-column min-width="180" prop="role" :formatter="arrayCellRenderer"
                               label="Roles"></el-table-column>
              <el-table-column min-width="60" prop="enabled" :formatter="booleanCellRenderer"
                               label="Enabled"></el-table-column>

              <el-table-column min-width="150"
                               header-align="center"
                               label="Actions">
                <div slot-scope="{row}" class="text-center">

                  <el-tooltip content="Update User"
                              :open-delay="300"
                              placement="top" v-if="!row.systemAccount">
                    <base-button type="info" size="" @click="showUpdateUser(row)">
                      <i class="tim-icons icon-cloud-upload-94"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip content="Delete User"
                              :open-delay="300"
                              placement="top" v-if="!row.systemAccount">
                    <base-button type="danger" size="" @click="deleteUser(row)">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>

                </div>
              </el-table-column>

            </el-table>

          </div>
        </card>
      </div>
    </div>


    <modal :show.sync="modals.showUpdateUsers"
           body-classes="p-0">
      <card type="secondary"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template slot="header">
          <h2 class="modal-title" id="exampleModalLabel">Update User '{{updateModel.user.userName}}'</h2>
        </template>
        <div>
          <base-input label="Username"
                      type="text"
                      disabled
                      placeholder="username" v-model="updateModel.user.userName">
          </base-input>
          <base-input
            type="email"
            required
            name="email"
            label="Email address"
            v-validate="updateModel.user.email"
            :error="getError('email')"
            v-model="updateModel.user.email"
          >
          </base-input>

          <base-input
            label="Password"
            required
            type="password"
            name="password"
            v-validate="updateModel.password"
            :error="getError('password')"
            v-model="updateModel.password"
          >
          </base-input>

          <base-input
            label="Confirm Password"
            required
            type="password"
            name="confirm"
            v-validate="updateModel.confirmPassword"
            :error="getError('confirm')"
            v-model="updateModel.confirmPassword"
          ></base-input>

          <base-checkbox class="" v-model="updateModel.user.enabled">
            Enabled
          </base-checkbox>

          <card title="Roles">
            <div class="card-body">
              <div v-for="role in updateModel.roles" v-if="role.role!=='SUPERADMIN'">
                <base-checkbox v-model="role.selected">
                  {{role.role}}
                </base-checkbox>
              </div>
            </div>
          </card>
          <card title="Branch">
            <div class="card-body">
              <div v-for="branch in updateModel.branches">
                <base-checkbox v-model="branch.selected">
                  {{branch.branchName}}
                </base-checkbox>
              </div>
            </div>
          </card>

          <card title="Default Branch">
            <div class="card-body">
              <h4 class="col-md-7">
              <BranchSelect
                :propergateGlobally="propergateGlobally">
              </BranchSelect>

            </h4>
            </div>
          </card>

        </div>
        <template slot="footer">
          <base-button type="primary" round @click="updateUser">Save changes</base-button>
          <base-button type="warning" round @click="closeUpdatePopup">Close</base-button>
        </template>
      </card>
    </modal>


  </div>
</template>

<script>
  import VeeValidate from 'vee-validate'
  import {AXIOS} from "../../config/http-commons";
  import {Table, TableColumn} from 'element-ui'
  import {Modal} from 'src/components'
  import BranchSelect from '../../components/custom/BranchSelect';

  export default {
    name: "UserAdmin",
    components: {
      VeeValidate,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      BranchSelect
    },
    props: {
      branchSelected: {type: Number, required: false, default: -1},
    },
    data() {
      return {
        propergateGlobally: false,
        users: {
          tableData: [{
            email: null,
            enabled: false,
            id: -1,
            defaultBranchLogicId: null,
            roles: [],
            userName: "Loading"
          }]
        },
        branches: {
          tableData: [
            {
              branchName: "Loading",
              branchProperties: "",
              clientId: -1,
              createdBy: null,
              creationDate: null,
              endDate: null,
              id: -1,
              lastModifiedBy: null,
              lastModifiedDate: null,
              logicId: -1,
              startDate: null,
              queues: [{
                logicId: -1,
                name: ''
              }]
            }
          ]
        },
        roles: [
          {'id': -1, 'role': 'Loading'}],
        model: {
          txtUsername: '',
          txtPassword: '',
          txtConfirmPassword: '',
          txtEmail: '',
          defaultBranchLogicId: -1,
        },
        modelValidations: {
          email: {
            required: true,
            email: true
          },
          password: {
            required: true,
            min: 5
          }
        },
        modals: {showUpdateUsers: false},
        updateModel: {
          roles: [],
          branches: [],
          password: '',
          confirmPassword: '',
          defaultBranchLogicId: -1,
          user: {
            branches: [],
            clientId: null,
            defaultBranchLogicId: -1,
            email: null,
            enabled: null,
            id: null,
            roles: [],
            systemAccount: true,
            userName: null,
            password: null,
            confirmPassword: null
          }
        },
      }
    },
    methods: {

      addUser() {
        console.log('Starting attempt to add user')
        if (this.model.txtUsername === '') {
          this.$notify({type: 'warning', message: 'Enter username'});
          return
        }
        let selectedRoles = [];
        let selectedBranches = [];

        this.roles.forEach(role => {
          if (role.selected)
            selectedRoles.push({id: role.id})
        });

        this.branches.tableData.forEach(branch => {
          if (branch.selected)
            selectedBranches.push({id: branch.id});
            this.model.defaultBranchLogicId = branch.logicId
        });
        let data = {
          email: this.model.txtEmail,
          userName: this.model.txtUsername,
          roles: selectedRoles,
          branches: selectedBranches,
          defaultBranchId: this.model.defaultBranchLogicId,
        };
        let axiosConfig = {
          headers: {
            "password": this.model.txtPassword,
          }
        };
        console.log('Post to add user: ' + '/rest/users/add:')
        console.log(data)
        AXIOS.post('/rest/users/add', data, axiosConfig).then(r => {
          this.updateAllTables();
        });
      },
      deleteUser(row) {
        AXIOS.delete("/rest/users/" + row.userName).then(r => {
          this.updateAllTables();
        });
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        console.log('validating user')
        this.$validator.validateAll().then(isValid => {
          console.log('user is valid we can emit and pass onto create')
          this.$emit('on-submit', this.UserAdmin, isValid)
          if (isValid)
            console.log('passing on to create')
            this.addUser()
        });
      },
      booleanCellRenderer(row, column, cellValue) {
        return row[column.property] === true ? 'Yes' : 'No';
      },
      arrayCellRenderer(row, colum, cellValue) {

        let roles = [];
        row.roles.forEach(role => {
          roles.push(role.role + ' ');
        });
        return roles
      },
      updateAllTables() {
        AXIOS.get('/rest/users').then(r => {
          this.users.tableData = r.data;
        });
        AXIOS.get('/rest/users/roles').then(r => {
          r.data.forEach(role => {
            role.selected = false
          });
          this.roles = r.data;
          this.updateModel.roles = r.data;
        });
        AXIOS.get('/rest/users/branch').then(r => {
          r.data.forEach(branch => {
            branch.selected = false
          });
          this.branches.tableData = r.data;
          this.updateModel.branches = r.data;
        });
      },
      closeUpdatePopup() {
        this.modals.showUpdateUsers = false
        this.updateAllTables();
      },
      showUpdateUser(row) {
        console.log('Called showUpdateUser')
        this.updateModel.user = row;
        this.updateModel.roles = this.roles;
        this.updateModel.branches = this.branches.tableData;
        this.updateModel.password = '';
        this.updateModel.confirmPassword = '';
        this.updateModel.defaultBranchLogicId = '';
        this.modals.showUpdateUsers = true;
        for (let i = 0; i < this.updateModel.roles.length; i++) {
          for (let j = 0; j < row.roles.length; j++) {
            let role = this.updateModel.roles[i];
            let selectedRole = row.roles[j];
            if (role.role === selectedRole.role) {
              role.selected = true;
            }
          }
        }

        for (let i = 0; i < this.updateModel.branches.length; i++) {
          for (let j = 0; j < row.branches.length; j++) {
            let role = this.updateModel.branches[i];
            let selectedRole = row.branches[j];
            if (role.branchName === selectedRole.branchName) {
              role.selected = true;
            }
          }
        }
      },
      updateUser() {
        console.log('Called updateUser')
        console.log('Selected Branch:')
        console.log(BranchSelect)

        console.log('Selected props:')
        console.log(this.branchSelected)

        // password checks
        let pass = this.updateModel.password;
        let confirmPass = this.updateModel.confirmPassword;
        if (pass !== '') {
          if (pass === confirmPass) {
            if (pass.length < 5) {
              this.$notify({type: 'danger', message: 'Password to short'});
              return;
            }
          } else {
            this.$notify({type: 'danger', message: 'Passwords do not match.'});
            return;
          }
        }


        let selectedRoles = [];
        this.updateModel.roles.forEach(r => {
          if (r.selected) selectedRoles.push(r)
        });

        let selectedBranch = [];
        this.updateModel.branches.forEach(r => {
          if (r.selected) {
            selectedBranch.push(r)
            console.log('Found selected branch:')
            console.log(r.logicId)
            this.updateModel.defaultBranchLogicId = r.logicId
          }
        });

        let data = this.updateModel.user;
        data.password = this.updateModel.password;
        data.roles = selectedRoles;
        data.branches = selectedBranch;
        data.defaultBranchId = this.updateModel.defaultBranchLogicId;
        let axiosConfig = {
          headers: {
            "password": data.password,
          }
        };
        console.log('this.updateModel:')
        console.log(this.updateModel)
        console.log('calling update user REST call. data:')
        console.log(data)
        AXIOS.put('/rest/users/' + this.updateModel.user.userName, data, axiosConfig).then(r => {
          this.modals.showUpdateUsers = false;
          this.updateModel.user = {};
          this.updateAllTables();

          this.$notify({
            type: 'success', message: 'User \'' + r.data.userName + '\' updated'
          });
        });

      },
    },
    created() {
      this.updateAllTables();
    }
  }
</script>

<style scoped>

</style>
