import { render, staticRenderFns } from "./UserAdmin.vue?vue&type=template&id=22cf4bdf&scoped=true&"
import script from "./UserAdmin.vue?vue&type=script&lang=js&"
export * from "./UserAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22cf4bdf",
  null
  
)

export default component.exports