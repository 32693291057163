<template>
  <div class="main-report-panel">
    <div>
      <card type='card-danger'  style="">
        <h1 class="card-title">Queue Stats</h1>
        <h4 class="card-subtitle">Use this page to quickly look at the applications statistics</h4>
      </card>
    </div>
    <div class="row">
      <!-- Run reort and live stats -->
      <div id="chart0of3" class="chartbox ">
        <card class="chart-card" title="Run Report" sub-title="Enter dates to run report">
          <div class="card-body">
            <div class="row ">
              <div class="col-md-12">
                <label>Select a branch to run the report</label>
                <branch-select style="width: 100%;" class="el-select-custom" :propergate-globally="false"
                               :include-all="true" @localBranchLogicId="updateLocallySelectBranchId"></branch-select>
              </div>
            </div>
            <div class="row " style="padding-top: 20px;">
              <div class="col-md-6">
                <base-input>
                  <h6>Start</h6>
                  <el-date-picker v-model="report.startDate"
                                  type="datetime"
                                  placeholder="Start Date and Time"
                                  format="dd-MM-yyyy HH:mm:ss"
                                  default-time="00:00:00">
                  </el-date-picker>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input>
                  <h6>End</h6>
                  <el-date-picker v-model="report.endDate"
                                  type="datetime"
                                  placeholder="End Date and Time"
                                  format="dd-MM-yyyy HH:mm:ss"
                                  default-time="23:59:59">
                  </el-date-picker>
                </base-input>
              </div>
            </div>
            <h6>Report Type</h6>
            <div class= "report-options" v-for="option in reportOptions" :key="option.value">
              <label>
                <input type="radio" :value="option.value" v-model="selectedReportOption" @click="reportTypeSelected(option.value)">
                {{ option.label }}
              </label>
            </div>
            <div class="row">
              <div class="btn-group col-md-12">
                <base-button style='margin: 0px' @click="runReport" :disabled="!runReportButtonEnable" class="animation-on-hover" type="primary" block
                             round>Run Report
                </base-button>

                <base-button style='margin: 0px' @click="runReportAsExcel" :disabled="!runReportButtonEnable" class="animation-on-hover" type="info" block
                             round>Download detailed report as Excel
                </base-button>
                <base-button style='margin: 0px' @click="runReportAsCSV" :disabled="!runReportButtonEnable" class="animation-on-hover" type="info" block
                             round>Download detailed report as CSV
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>


      <!-- Total Visits By Date table grid, Chart 1 of 3 -->
      <div id="chart1of3"
           class="chartbox "
           :class="{fullscreen: chart1Focus===0} " >
        <card class="chart-card" sub-title="" >


          <div class="holder">
            <h5 class="card-category"><i class="tim-icons icon-chart-bar-32 text-primary "></i>
              Ticket Statistics Totals
            </h5>
            <button class="btn animation-on-hover btn-round btn-info" @click="toogleChart1(this)" v-if="chart1Focus===-1">Full screen</button>
            <button class="btn animation-on-hover btn-round btn-info" @click="toogleChart1(this)" v-else-if="chart1Focus===0">Restore</button>
            <button class="btn animation-on-hover btn-round btn-info" @click="exportToPDF('chart1of3')" v-if="chart1Focus===0">Print</button>

            <div class="report-pdf-title" v-if="chart1Focus===0">
              {{this.selectedReportOption}} Ticket Statistics Totals from {{formatDateTimeForPreview(this.report.startDate)}} to {{formatDateTimeForPreview(this.report.endDate)}}
            </div>

            <el-table ref="table" :data="visitsByDateTable.data" style="width: 100%">
              <el-table-column v-for="column in visitsByDateTable.tableColumns"
                               v-bind:property="column.id"
                               v-bind:label="column.title"
                               v-bind:width="column.width"
                               v-bind:value="column.format">

              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>


      <!-- Total Visits By Date line chart, Chart 2 of 3-->
      <div id="chart2of3"
           class="chartbox "
           :class="{fullscreen: chart2Focus===0} ">

        <card class="chart-card" type="chart">


          <template slot="header">
            <h5 class="card-category"><i class="tim-icons icon-bell-55 text-primary "></i>
              Total Visits By Date Stats
            </h5>
            <button class="btn animation-on-hover btn-round btn-info padding:20" @click="toogleChart2(this)" v-if="chart2Focus===-1">Full screen</button>
            <button class="btn animation-on-hover btn-round btn-info" @click="toogleChart2(this)" v-else-if="chart2Focus===0">Restore</button>
            <button class="btn animation-on-hover btn-round btn-info" @click="exportToPDF('chart2of3')" v-if="chart2Focus===0">Print</button>

            <div class="report-pdf-title" v-if="chart2Focus===0">
              {{this.selectedReportOption}} Total Visits By Date Stats from {{formatDateTimeForPreview(this.report.startDate)}} to {{formatDateTimeForPreview(this.report.endDate)}}
            </div>

          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100% padding: 10px"
              :chart-data="visitsByDateGraph.chartData"
              :gradient-colors="visitsByDateGraph.gradientColors"
              :gradient-stops="visitsByDateGraph.gradientStops"
              :extra-options="visitsByDateGraph.extraOptions"
              ref="refVisitsByDateGraph"
            >
            </line-chart>
          </div>
        </card>
      </div>


      <!-- Visits Total and Avg Wait Time and Transaction Times bar chart by period, Chart 3 of 3-->
      <div id="chart3of3"
           class="chartbox "
           :class="{fullscreen: chart3Focus===0} ">

        <card class="chart-card" type="chart">


          <template slot="header">
            <h5 class="card-category"><i class="tim-icons icon-send text-success "></i>
              Total Visits By Date Chart </h5>
            <button class="btn animation-on-hover btn-round btn-info" @click="toogleChart3(this)" v-if="chart3Focus===-1">Full screen</button>
            <button class="btn animation-on-hover btn-round btn-info" @click="toogleChart3(this)" v-else-if="chart3Focus===0">Restore</button>
            <button class="btn animation-on-hover btn-round btn-info" @click="exportToPDF('chart3of3')"  v-if="chart3Focus===0">Print</button>

            <div class="report-pdf-title" v-if="chart3Focus===0">
              {{this.selectedReportOption}} Total Visits By Date Chart from {{formatDateTimeForPreview(this.report.startDate)}} to {{formatDateTimeForPreview(this.report.endDate)}}
            </div>

          </template>
          <div class="chart-area">
            <bar-chart
              style="height: 100%"
              :chart-data="summaryTotalsByDatesBarGraph.chartData"
              :gradient-stops="summaryTotalsByDatesBarGraph.gradientStops"
              :extra-options="summaryTotalsByDatesBarGraph.extraOptions"
              :key="summaryTotalsByDatesBarGraph.dataCollectionKey"
              ref="refSummaryBarChart"
            >
            </bar-chart>


          </div>
        </card>
      </div>



    </div>
  </div>
</template>
<script>
  import LineChart from 'src/components/Charts/LineChart';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import BarChart from 'src/components/Charts/BarChart';
  import * as chartConfigs from 'src/components/Charts/config';
  import UserTable from '../../pages/Dashboard/UserTable';
  import StatsCard from 'src/components/Cards/StatsCard';
  import config from 'src/config';
  import {DatePicker, TimeSelect, Table, TableColumn, Radio, RadioGroup, RadioButton} from 'element-ui'
  import ElementUI from "element-ui";
  import {AXIOS} from "../../config/http-commons";
  import BranchSelect from '../../components/custom/BranchSelect'
  import { ref } from 'vue'
  import xlsx from "json-as-xlsx"
  import html2pdf from "html2pdf.js";
  import jsPDF from "jspdf";
  import html2canvas from 'html2canvas';

  const value = ref('')
  Chart.plugins.register(ChartDataLabels);
  Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
    color: '#FE777B',
    anchor: 'end',
    align: 'top',
    offset: 5,

  });

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      LineChart,
      BarChart,
      StatsCard,
      UserTable,
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      BranchSelect,
      RadioGroup,
      RadioButton,
      Radio
    },
    data() {
      return {
        chart1Focus: -1,
        chart2Focus: -1,
        chart3Focus: -1,
        chart1Width: -1,
        chart2Width: -1,
        chart3Width: -1,
        chart1Height: -1,
        chart2Height: -1,
        chart3Height: -1,
        fullScreenReportHeight: -1,
        fullScreenReportWidth: -1,
        fullScreenReportRatio: -1,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        runReportButtonEnable: false,
        reportSettings: {
          fileName: "ExportedReport",
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        },
        excelData: [{
          sheet: "Visit Detail",
          columns: [
            { label: "Id", value: "Id" }, // Top level data
            { label: "Ticket", value: "Ticket" }, // Column format
            { label: "Wait_Time", value: "Wait_Time" }, // Deep props and column format
            { label: "Transaction_Time", value: "Transaction_Time" },
            { label: "Branch_Name", value: "Branch_Name" },
            { label: "Queue_Name", value: "Queue_Name" },
            { label: "Created_Time", value: "Created_Time", format: "d-mmm-yyyy h:mm AM/PM"},
            { label: "Created_Station_Name", value: "Created_Station_Name" },
            { label: "Printer_MAC_Address", value: "Printer_MAC_Address" },
            { label: "Resource", value: "Resource" }
          ],
          content: [
          ]
        }],
        selectedReportOption: '',
        reportOptions: [
          { value: 'Hourly', label: 'Hourly' },
          { value: 'Daily', label: 'Daily' },
          { value: 'Weekly', label: 'Weekly' },
          { value: 'Monthly', label: 'Monthly' }
        ],
        chartOptions: {
          responsive: true
        },
        reportTicketsCounts: [],
        reportAvgWaitTimes: [],
        reportAvgTransTimes: [],
        report: {
          startDate: Date(),
          endDate: Date(),
          grouping: "",
          api: {
            addAndCalls: [],
            date: [],
            amounts: {
              addAndCallEventsAmount: 0,
              addEventsAmount: 0,
              averageWaitTimeHHmmSS: "00:00:00",
              callEventsAmount: 0,
              totalEventsAmounts: 0,
              totalVisits: 0,
              totalWaitTimeHHmmSS: "00:00:00",
            },
            headers: {
              firstColumn: "First",
              secondColumn: "Second",
              thirdColumn: "third"
            }
          }
        },
        live: {
          api: {
            amounts: {
              addAndCallEventsAmount: 0,
              addEventsAmount: 0,
              averageWaitTimeHHmmSS: "00:00:00",
              callEventsAmount: 0,
              totalEventsAmounts: 0,
              totalVisits: 0,
              totalWaitTimeHHmmSS: "00:00:00",
            },
            currentAmount: 1
          },
        },
        hoursSinceMidnight: new Date().getHours(),
        visitsByDateTable: {
          data:[0],
          tableColumns: [{
            id: 'date',
            title: 'Date',
            width: '100'
          }, {
            id: 'total_tickets',
            title: 'Total No of Tickets',
            width: '210'
          }, {
            id: 'avg_wait_time',
            title: 'Average Wait Time (Mins)',
            width: '240'
          }, {
            id: 'avg_trans_time',
            title: 'Average Transaction Time (Mins)',
            width: '280'
          }
          ]
        },



        summaryTotalsByDatesBarGraph: {
          dataCollectionKey: -1,
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: [ '2022/07/01', '2022/07/02', '2022/07/03', '2022/07/04', '2022/07/05', '2022/07/06', '2022/07/07', '2022/07/08', '2022/07/09', '2022/07/10', '2022/07/11', '2022/07/12', '2022/07/13', '2022/07/14',
              '2022/07/15', '2022/07/16', '2022/07/17', '2022/07/18', '2022/07/19', '2022/07/20', '2022/07/21', '2022/07/22', '2022/07/23', '2022/07/24', '2022/07/25', '2022/07/26', '2022/07/27', '2022/07/28', '2022/07/29', '2022/07/30', '2022/07/31' ],
            datasets: [
              {
                label: "Tickets",
                backgroundColor: "green",
                fill: true,
                borderColor: "green",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
              },
              {
                label: "Avg Wait Time",
                //backgroundColor: "#ff0000",
                backgroundColor: "#ff0000",
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: "#ff0000",
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
              },
              {
                label: "Avg Trans Time",
                backgroundColor: "#0099ff",
                borderColor: "#0099ff",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: "#0099ff",
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
              },
            ]
          },
          gradientColors: [
            'rgba(66,134,121,0.15)',
            'rgba(66,134,121,0.0)',
            'rgba(66,134,121,0)'
          ],
          gradientStops: [1, 0.4, 0]
        },




        visitsByDateGraph: {
          extraOptions: chartConfigs.purpleChartOptions,
          chartData: {
            labels: [this.formatDateForPreview(new Date())],
            datasets: [
              {
                label: 'Tickets',
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [0]

              },
            ],
            options: {
              plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                  color: '#eb3645',
                  anchor: top,
                  offset: 25,
                  labels: {
                    title: {
                      font: {
                        weight: 'bold'
                      }
                    },
                    value: {
                      color: 'green'
                    }
                  }
                }
              }
            },

          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0]
        },
        addAndCallByDatesGraph: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: [this.formatDateForPreview(new Date())],
            datasets: [
              {
                label: 'Amount',
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [0]
              }
            ]
          },
          gradientColors: [
            'rgba(66,134,121,0.15)',
            'rgba(66,134,121,0.0)',
            'rgba(66,134,121,0)'
          ],
          gradientStops: [1, 0.4, 0]
        },
        subscriptions: [],
        localSelectedBranchId: -1,
        reporting_options: [
          {
            value: 'Daily',
            label: 'Daily',
          },
          {
            value: 'Weekly',
            label: 'Weekly',
          },
          {
            value: 'Monthly',
            label: 'Monthly',
          }
        ]
      };
    },

    computed: {
      reportCurrentDate()
      {
        let yourDate = new Date()
        return yourDate.toISOString().split('T')[0];

      }
    },
    methods: {
      initStartDate() {
        console.debug('called initStartDate');

        if (window.location.hostname.indexOf('localhost') >= 0) {
          this.dev = true;
          let myStartDate = new Date()
          myStartDate.setFullYear(2022, 11, 1);
          myStartDate.setHours(0, 0, 0, 0);
          this.report.startDate = myStartDate;
          console.error("local mode setting initStartDate: " + this.report.startDate)
        }
        else {

          let myStartDate = new Date()
          myStartDate.setMonth(new Date().getMonth() - 1);
          myStartDate.setHours(0, 0, 0, 0);
          console.debug('   initStartDate: ' + myStartDate);
          this.report.startDate = myStartDate
        }
      },
      initEndDate() {
        console.debug('called initEndDate');
        if (window.location.hostname.indexOf('localhost') >= 0) {
          this.dev = true;
          let myEndDate = new Date()
          myEndDate.setFullYear(2022, 11, 31);
          myEndDate.setHours(23, 59, 59, 999);
          this.report.endDate = myEndDate;
          console.error("local mode setting initStartDate: " + myEndDate)
        }
        else {
          let myEndDate = new Date()
          myEndDate.setMonth(new Date().getMonth() - 1);
          myEndDate.setHours(23, 59, 59, 999);
          this.report.endDate = myEndDate
        }
      },
      queueSelected(event) {
        console.log('queueSelected event:' + event + ' event.id:' + event.id + ' event.logicId:' + event.logicId)
        console.log('queueSelected event:' + JSON.stringify(event, null, 2))

        this.selectedQueueId = event.logicId;
        this.checkIfEverythingSelected()
      },
      reportTypeSelected(selectedReportOption) {
        console.log('reportTypeSelected event:' + event + ' event.id:' + event.id + ' event.logicId:' + event.logicId)
        this.selectedReportOption = selectedReportOption
        this.checkIfEverythingSelected()
      },
      checkIfEverythingSelected() {
        console.log('checkIfEverythingSelected called');
        if (this.selectedReportOption != null && this.localSelectedBranchId != null) {

          this.runReportButtonEnable = true;
          console.log('runReportButtonEnable set to true');

        }
      },
      toogleChart1(index) {
        console.debug( 'called toogleChart1, index: '+ index);
        const chartBox = document.querySelectorAll('.chartbox')
        if (this.chart1Focus === -1) {
          console.debug( 'called toogleChart1, currently normal');
          let retElement = document.getElementById('chart1of3');
          this.chart1Width = retElement.style.width;
          this.chart1Height = retElement.style.height;
          this.chart1Focus = 0
        }
        else {
          if (this.chart1Focus === 0) {
            console.debug('called toogleChart1, currently fullscreen');
            let retElement = document.getElementById('chart1of3');
            retElement.style.width = this.chart1Width ;
            retElement.style.height = this.chart1Height;
            this.chart1Focus = -1;
            console.debug('called toogleChart1, chart1Focus:' + this.chart1Focus);
          }
        }
        console.log(chartBox)
        //chartboxFullscreen

      },
      toogleChart2(index) {
        console.debug( 'called toogleChart2, index: '+ index);
        const chartBox = document.querySelectorAll('.chartbox')
        if (this.chart2Focus === -1) {
          console.debug( 'called toogleChart2, currently normal');
          let retElement = document.getElementById('chart2of3');
          this.chart2Width = retElement.style.width;
          this.chart2Height = retElement.style.height;
          this.chart2Focus = 0
        }
        else {
          if (this.chart2Focus === 0) {
            console.debug('called toogleChart2, currently fullscreen');
            let retElement = document.getElementById('chart2of3');
            retElement.style.width = this.chart2Width ;
            retElement.style.height = this.chart2Height;
            this.chart2Focus = -1;
            console.debug('called toogleChart2, chart2Focus:' + this.chart2Focus);
          }
        }
        console.log(chartBox)
        //chartboxFullscreen

      },
      toogleChart3(index) {
        console.debug( 'called toogleChart3, index: '+ index);
        const chartBox = document.querySelectorAll('.chartbox')
        if (this.chart3Focus === -1) {
          console.debug( 'called toogleChart3, currently normal');
          let retElement = document.getElementById('chart3of3');
          this.chart3Width = retElement.style.width;
          this.chart3Height = retElement.style.height;
          this.chart3Focus = 0
        }
        else {
          if (this.chart3Focus === 0) {
            console.debug('called toogleChart3, currently fullscreen');
            let retElement = document.getElementById('chart3of3');
            retElement.style.width = this.chart3Width ;
            retElement.style.height = this.chart3Height;
            this.chart3Focus = -1;
            console.debug('called toogleChart3, chart3Focus:' + this.chart3Focus);
          }
        }
        console.log(chartBox)
        //chartboxFullscreen

      },
      callbackFunc(pdf) {

          var iframe = document.createElement('iframe');
          iframe.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:500px');
          document.body.appendChild(iframe);
          iframe.src = pdf.output('datauristring');

      },
      exportToPDF(element) {
        console.debug('Called exportToPDF element:' + element)
        let retElement = document.getElementById(element);
        console.debug('BoundingRec:' + JSON.stringify(retElement.getBoundingClientRect()));
        this.fullScreenReportHeight = retElement.getBoundingClientRect().height;
        this.fullScreenReportWidth = retElement.getBoundingClientRect().width;
        this.fullScreenReportRatio = this.fullScreenReportWidth / this.fullScreenReportHeight;


        let newElem = retElement.cloneNode(true);

        console.log(newElem)

        if (element === 'chart1of3') {

          //newElem.style.width = '1200px';
          //newElem.style.height = '1480px';
          //html2pdf(newElem, {
          //  margin: [1,-84,1,1],
          //  filename: "ticket-statistics-total-" + this.reportCurrentDate + ".pdf",
          //  html2canvas: {

          //    width: 1200, height: 1480,

          //  },
          //  jsPDF: {
          //    unit: 'pt',
          //    width: 1200, height: 1480,
          //    format: 'A4',
          //    orientation: 'portrait'}
          //});
          console.debug('Current Window Width:' + this.windowWidth + ' Current Window Height:' + this.windowHeight + ' window.devicePixelRatio:' + window.devicePixelRatio)
          var doc = new jsPDF(
            {
              unit: 'mm',
              format: 'A4',
              orientation: 'portrait'
            }
          );

          html2canvas(retElement, {
            scale: .8 // increase the scale to improve the quality of the output
          }).then(canvas => {
            // Convert the canvas to a PDF using jsPDF
            const pdf = new jsPDF('landscape', 'mm' );
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 297, 297/this.fullScreenReportRatio);
            pdf.save( "total-visits-by-date-chart-" + this.reportCurrentDate + ".pdf");
          });

        }
        else if (element === 'chart2of3')
        {
          //retElement.style.width = '3400px';
          //retElement.style.height = '2280px';
          //html2pdf(retElement, {
          //  margin: [1,-40,1,1],
          //  filename: "total-visits-by-date-" + this.reportCurrentDate + ".pdf",
          //  html2canvas: {
          //    width: 3400,
          //    height: 2280,
          //  },
          //  jsPDF: {
          //    unit: 'pt',
          //    width: 3400,
          //    height: 2280,
          //    format: 'A4',
          //    orientation: 'landscape'
          //  }
          //});
          console.debug('Current Window Width:' + this.windowWidth + ' Current Window Height:' + this.windowHeight + ' window.devicePixelRatio:' + window.devicePixelRatio)
          var doc = new jsPDF(
            {
              unit: 'mm',
              format: 'A4',
              orientation: 'landscape'
            }
          );

          html2canvas(retElement, {
            scale: .8 // increase the scale to improve the quality of the output
          }).then(canvas => {
            // Convert the canvas to a PDF using jsPDF
            const pdf = new jsPDF('landscape', 'mm' );
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 297, 297/this.fullScreenReportRatio);
            pdf.save( "total-visits-by-date-chart-" + this.reportCurrentDate + ".pdf");
          });



        }
        else if (element === 'chart3of3')
        {
          console.debug('Current Window Width:' + this.windowWidth + ' Current Window Height:' + this.windowHeight + ' window.devicePixelRatio:' + window.devicePixelRatio)
          var doc = new jsPDF(
            {
            unit: 'mm',
            format: 'A4',
            orientation: 'landscape'
          }
          );

          html2canvas(retElement, {
            scale: .8 // increase the scale to improve the quality of the output
          }).then(canvas => {
            // Convert the canvas to a PDF using jsPDF
            const pdf = new jsPDF('landscape', 'mm' );
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 297, 297/this.fullScreenReportRatio);
            pdf.save( "total-visits-by-date-chart-" + this.reportCurrentDate + ".pdf");
          });

          //doc.html("<div>Hello world!</div>");
          //doc.save("total-visits-by-date-chart-" + this.reportCurrentDate + ".pdf")

         // html2pdf(retElement, {
         //     margin: [0,-14,0,0],
         //     filename: "total-visits-by-date-chart-" + this.reportCurrentDate + ".pdf",
         //     html2canvas: {
         //       width: this.windowWidth * 0.4,
         //       height: this.windowHeight * 0.93,
                //windowWidth: 1920,
                //windowHeight: 1280,
         //     },
         //     jsPDF: {
         //       unit: 'mm',
         //       width: 297,
         //       height: 210,
         //       format: 'A4',
         //       orientation: 'landscape'
         //     }
         //   });

        }
      },
      runReport() {
        console.debug('Called runReport')
        console.debug('runReport this.report.startDate:' + this.report.startDate + ' endDate:' + this.report.endDate)
        let startDate = this.formatDateTime(this.report.startDate);
        let endDate = this.formatDateTime(this.report.endDate);
        console.debug('runReport startDate:' + startDate + ' endDate:' + endDate)
        if (this.localSelectedBranchId === -1) {
          this.$notify({type: 'warning', message: 'Select a branch or choose all'})
          return;
        }
        if (this.localSelectedBranchId == -2) {
          AXIOS.get('/rest/stat/data/start/' + startDate + "/end/" + endDate).then(response => {
            this.report.api = response.data;
            this.fillOutReport();
          });
          return
        }
        if (this.selectedReportOption == 'Hourly') {
          this.visitsByDateTable.tableColumns[0].title = "Hour";
          AXIOS.get('/rest/stat/data/branch/' + this.localSelectedBranchId + '/start/' + startDate + "/end/" + endDate + "/reportType/hourly").then(response => {
            this.report.api = response.data;
            this.fillOutReport();
          });

        }
        else if (this.selectedReportOption == 'Daily') {
          this.visitsByDateTable.tableColumns[0].title = "Date";
          AXIOS.get('/rest/stat/data/branch/' + this.localSelectedBranchId + '/start/' + startDate + "/end/" + endDate + "/reportType/daily").then(response => {
            this.report.api = response.data;
            this.fillOutReport();
          });
        }
        else if (this.selectedReportOption == 'Weekly') {
          this.visitsByDateTable.tableColumns[0].title = "Week";
          AXIOS.get('/rest/stat/data/branch/' + this.localSelectedBranchId + '/start/' + startDate + "/end/" + endDate + "/reportType/weekly").then(response => {
            this.report.api = response.data;
            this.fillOutReport();
          });
        }
        else if (this.selectedReportOption == 'Monthly') {
          this.visitsByDateTable.tableColumns[0].title = "Month";
            AXIOS.get('/rest/stat/data/branch/' + this.localSelectedBranchId + '/start/' + startDate + "/end/" + endDate + "/reportType/monthly").then(response => {
              this.report.api = response.data;
              this.fillOutReport();
            });
          }
      },

      fillOutReport() {
        console.debug('called fillOutReport');
        console.debug('check this.report.api result: ' + JSON.stringify(this.report.api));


        let listOfDatesAndValues = this.report.api.addAndCalls;
        console.debug('check this.report.api.addAndCalls: ' + JSON.stringify(this.report.api.addAndCalls));
        let orderedEvents = {};
        Object.keys(listOfDatesAndValues).sort().forEach(function (key) {
          orderedEvents[key] = listOfDatesAndValues[key];
        });

        let labels = Object.keys(orderedEvents);
        let values = Object.values(orderedEvents);

        console.debug('check this.report.api.addAndCalls: labels ' + JSON.stringify(labels));
        console.debug('check this.report.api.addAndCalls: values ' + JSON.stringify(values));

        this.addAndCallByDatesGraph.chartData.labels = this.report.api.reportDateSeries;
        this.addAndCallByDatesGraph.chartData.datasets[0].data = this.report.api.reportTicketCount;

        //obj.put("reportDateSeries", reportDateSeries);
        //obj.put("reportTicketCountSeries", reportTicketCount);
        //obj.put("reportAvgWaitTimeSeries", reportAvgWaitTime);
        //obj.put("reportAvgTransTimeSeries", reportAvgTransTime);


        //this.$refs.addAndCallByDatesGraph.onDemandRender(this.addAndCallByDatesGraph.chartData)


        let totalVisitsByDateChart = {

            data: [
              {
                date: "2022/07/01",
                total_tickets: 1,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/02",
                total_tickets: 1,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/03",
                total_tickets: 1,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/04",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              }, {
                date: "2022/07/05",
                total_tickets: 22,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/06",
                total_tickets: 2,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/07",
                total_tickets: 15,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/08",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              }, {
                date: "2022/07/09",
                total_tickets: 22,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/10",
                total_tickets: 2,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/11",
                total_tickets: 15,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/12",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              }, {
                date: "2022/07/13",
                total_tickets: 22,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/14",
                total_tickets: 2,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/15",
                total_tickets: 15,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/16",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              }, {
                date: "2022/07/17",
                total_tickets: 22,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/18",
                total_tickets: 2,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/19",
                total_tickets: 15,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/20",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              }, {
                date: "2022/07/21",
                total_tickets: 22,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/22",
                total_tickets: 2,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/23",
                total_tickets: 15,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/24",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/25",
                total_tickets: 22,
                avg_wait_time: 14,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/26",
                total_tickets: 2,
                avg_wait_time: 1,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/27",
                total_tickets: 15,
                avg_wait_time: 27,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/29",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/30",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              },
              {
                date: "2022/07/31",
                total_tickets: 29,
                avg_wait_time: 4,
                avg_trans_time: 1.2
              }
            ]
        };
        this.visitsByDateTable.data =  this.report.api.visitsByDateTable
        //totalVisitsByDateChart.data;

        //console.debug('set visitsByDateTable using totalVisitsByDateChart:' + JSON.stringify(this.visitsByDateGraph.chartData));
        this.visitsByDateGraph.chartData.labels = this.report.api.reportDateSeries;
        this.visitsByDateGraph.chartData.datasets[0].data = this.report.api.reportTicketCountSeries;

        this.summaryTotalsByDatesBarGraph.chartData.labels = this.report.api.reportDateSeries;

        this.reportTicketsCounts = this.report.api.reportTicketCountSeries;
        this.reportAvgWaitTimes = this.report.api.reportAvgWaitTimeSeries;
        this.reportAvgTransTimes = this.report.api.reportAvgTransTimeSeries;

        this.summaryTotalsByDatesBarGraph.labels = this.report.api.reportDateSeries;
        this.summaryTotalsByDatesBarGraph.chartData.datasets[0].data =  this.report.api.reportTicketCountSeries;
        this.summaryTotalsByDatesBarGraph.chartData.datasets[1].data = this.report.api.reportAvgWaitTimeSeries;
        this.summaryTotalsByDatesBarGraph.chartData.datasets[2].data = this.report.api.reportAvgTransTimeSeries;


        let now = new Date();
        console.debug('Date:' + now);
        let ticks = now.getTime();
        console.debug('getTime:' + now.getTime());
        this.summaryTotalsByDatesBarGraph.dataCollectionKey = ticks;
        console.debug('check summaryTotalsByDatesBarGraph.dataCollectionKey: ' + JSON.stringify(this.getBarGraphChartKey));


        this.$refs.refVisitsByDateGraph.onDemandRender(this.visitsByDateGraph.chartData);
//        this.$refs.refSummaryBarChart.onDemandRender(this.summaryTotalsByDatesBarGraph.chartData);
        this.$notify({type: 'success', message: 'Report ran successfully'})



      },
      updateLocallySelectBranchId(selectedBranchId) {
        this.localSelectedBranchId = selectedBranchId
      },
      runReportAsExcel() {
        console.debug('called runReportAsExcel');
        ///rest/stat/raw/csv/start/20180101/end/20191101
        let startDate = this.formatDateTime(this.report.startDate);
        let endDate = this.formatDateTime(this.report.endDate);
        if (this.localSelectedBranchId === -1) {
          this.$notify({type: 'warning', message: 'Select a branch or choose all'})
          return;
        }
        if (this.localSelectedBranchId == -2) {
          AXIOS.get('/rest/stat/rawjson/start/' + startDate + "/end/" + endDate).then(response => {
            //console.debug('response: '+ JSON.stringify(response.data));
            this.excelData[0].content = response.data.content;
            xlsx(this.excelData, this.reportSettings)
          });
          return
        }
        //LAZY
        AXIOS.get('/rest/stat/rawjson/branch/' + this.localSelectedBranchId + '/csv/start/' + startDate + "/end/" + endDate).then(response => {
          //console.debug('response: '+ JSON.stringify(response.data));
          this.excelData[0].content = response.data.content;
          this.reportSettings.fileName = "ExportedReport" + this.reportCurrentDate;
          xlsx(this.excelData, this.reportSettings)
        });
      },
      runReportAsCSV() {
        console.debug('called runReportAsCSV');
        ///rest/stat/raw/csv/start/20180101/end/20191101
        let startDate = this.formatDateTime(this.report.startDate);
        let endDate = this.formatDateTime(this.report.endDate);
        if (this.localSelectedBranchId === -1) {
          this.$notify({type: 'warning', message: 'Select a branch or choose all'})
          return;
        }
        if (this.localSelectedBranchId == -2) {
          AXIOS.get('/rest/stat/raw/csv/start/' + startDate + "/end/" + endDate).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'csv report.csv');
            document.body.appendChild(link);
            link.click();
          });
          return
        }
        //LAZY
        AXIOS.get('/rest/stat/raw/branch/' + this.localSelectedBranchId + '/csv/start/' + startDate + "/end/" + endDate).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'csv report.csv');
          document.body.appendChild(link);
          link.click();
        });
      },
      liveDataFill() {
        console.debug('called liveDataFill');
        AXIOS.get("/rest/stat/live/data/").then(response => {
          this.live.api = response.data
        });
      },
      formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('');
      },
      formatDateTime(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          //hh24 = '' + d.toTimeString().split(' ')[0].replaceAll(":", "");
          hh24 = ' ' + d.toTimeString().split(' ')[0];

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day, hh24].join('');
      },
      formatDateForPreview(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
      },
      formatDateTimeForPreview(date) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hh24 = ' ' + d.toTimeString().split(' ')[0];

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day, hh24].join('/');
      }
    },
    mounted() {
      console.debug('called mounted');
      this.liveDataFill();
      this.initStartDate();
      this.initEndDate();
      this.subscriptions.push(this.$stompClient.subscribe('/topic/call', (data) => {
        this.liveDataFill();
      }, {id: 'statCall'}));
      this.subscriptions.push(this.$stompClient.subscribe('/topic/add', (data) => {
        this.liveDataFill();
      }, {id: 'statAdd'}));
      this.subscriptions.push(this.$stompClient.subscribe('/topic/end', (data) => {
        this.liveDataFill();
      }, {id: 'statEnd'}));
    },
    beforeDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
    }
  };
</script>
<style scoped>
  .el-select-custom {
    width: 100%;
  }
  .html {
    overflow: hidden !important;
  }
  .main-report-panel {

  }
  .table-card {
    flex-direction: column;
    overflow-y: auto;
  }
  .chart-card {

    //overflow-y: clip;
    width: 98%;
  }
  .chartbox {
    width: 45vw;
    height: 48vh;
    border: 8px;
    margin-outside: 8px;
    overflow-y: auto;
    margin-bottom: 15px;
  }
  .chartbox.fullscreen {
    position:absolute;
    left:4.5vw;
    width:94.3vw;
    height:100%;
    z-index:1000;
    background-color: #1e1e2f;
    display: flex;
  }
  .chartbox.hide {
    position:absolute;
    top:0;
    left:40px;
    width:0%;
    height:0%;
    z-index:-1;
    background-color: #00bbff;
  }
  .card-header {
    display: flex !important;
    flex-direction: column;
   }
  .card-chart
  {
    width: 100%;
  }
  .chart-area
  {
    width: 100%;
  }
  .report-pdf-title{
    border: solid 4px white;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;

    font-size:22px;
  }
  .zoom{
    border: solid 2px white;
    padding: 5px;
    margin-bottom: 5px;
    font-size:12px;

  }
  .close{
    border: solid 2px white;
    padding: 10px;
    margin-bottom: 10px;
    font-size:15px;
  }
  .report-options {
    display: inline-block;
    border-style:none;
    margin-left:20px;
    margin-right:20px;
    margin-bottom: 15px;
    font-size:21px;
  }
</style>
